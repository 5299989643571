import {
  Text,
} from '@chakra-ui/react';
import { memo } from 'react';
import { Control } from 'react-hook-form';
import { ShopSearchSpinner } from '../../../Sp/ModalParts/ShopSearchSpinner';
import {
  ConditionBox,
  ConditionBoxProps
} from '../ConditionBox';
import { ConditionTitle } from '../ConditionTitle';
import { checkboxStyleProps } from '../SearchForm';
import { CheckboxLabel } from './CheckboxLabel';
import { Image } from '@/components/Image';
import { ControlledCheckbox } from '@/components/Input/Controlled/ControlledCheckbox';
import { ShopSearchConditions } from '@/features/shops/types/shopSearch';
import { ShopSearchParams } from '@/types/shopSearch';

export type CitiesSectionProps = {
  isLoading: boolean;
  control: Control<ShopSearchParams>;
  cities: ShopSearchConditions['cities'];
} & Omit<ConditionBoxProps, 'children'>;

const NonMemoCitiesSection = ({
  isLoading,
  control,
  cities,
  ...rest
}: CitiesSectionProps) => {
  return (
    <ConditionBox
      {...rest}
    >
      <ConditionTitle>
        <Image
          src={'/images/search_icon/city_icon.png'}
          alt={'市区町村で絞り込む'}
          width={24}
          height={24}
        />
        <Text
          as={'h2'}
          pl={2}
        >
          市区町村
        </Text>
      </ConditionTitle>
      {
        isLoading ? (
          <ShopSearchSpinner
            searchTitle={'市区町村'}
          />
        ) : (
          <>
            {cities.items.map((city) => (
              <ControlledCheckbox<ShopSearchParams>
                {...checkboxStyleProps}
                key={city.id}
                name={'cityIds'}
                control={control}
                value={city.id}
              >
                <CheckboxLabel>
                  {city.cityName}
                </CheckboxLabel>
              </ControlledCheckbox>
            ))}
          </>
        )
      }
    </ConditionBox>
  );
};

export const CitiesSection = memo(NonMemoCitiesSection);
